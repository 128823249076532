import AbstractComponent from './abstractComponent';

export default class VideoPan extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            (function () {
                let lazyYoutubeIframe = function (event) {
                    const element = event.currentTarget;
                    const vidType = element.dataset['videoType'];
                    const vid = element.dataset['youtubeId'];
                    let iframe = '';
                    if (vidType == 'vimeo') {
                        iframe = `<iframe src="https://player.vimeo.com/video/${vid}?autoplay=1" frameborder="0"  allow=autoplay allowfullscreen height="${element.dataset.height}" width="${element.dataset.width}"/>`;
                    } else if (vidType == 'youtube') {
                        iframe = `<iframe src="https://www.youtube.com/embed/${vid}?autoplay=1" frameborder=0 allowfullscreen height="${element.dataset.height}" width="${element.dataset.width}" />`;
                    }
                    $(element)
                        .html(iframe)
                        .attr('data-youtube-id', '#' + vid);
                };

                $(document).on(
                    'click',
                    '[data-youtube-id]:not([data-youtube-id^="#"])',
                    lazyYoutubeIframe
                );
            })();
        }
    }
}
