import AbstractComponent from './abstractComponent';

export default class AboutScroll extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {

            let figure = $(".about-link");
            let vid = figure.find("video");

            [].forEach.call(figure, function (item,index) {
                item.addEventListener('mouseover', hoverVideo.bind(item,index), false);
                item.addEventListener('mouseout', hideVideo.bind(item,index), false);
            });

            function hoverVideo(index) {
                vid[index].play(); 
            }

            function hideVideo(index) {
                vid[index].pause(); 
            }

            $(".about-link .about-show").mouseover(function () {
                $("body").css('overflow', 'hidden').addClass('about-link-hover');
                $(this).css('position', 'relative');
                $(this).next('.about-showHide').addClass('animate');
            })
            $(".about-link .about-show").mouseout(function () {
                $("body").css('overflow', 'inherit').removeClass('about-link-hover');
                $(this).css('position', 'static');
                $(this).next('.about-showHide').removeClass('animate');
            });
        }
    }
}
