import 'core-js';

import './assets/css/style.scss';
import SplashScreenModule from './assets/js/splash-screen-module';
import AboutClients from './assets/js/aboutClients';
import CareerHeader from './assets/js/careerHeader';
import PeopleExecutive from './assets/js/peopleExecutive';
import NewsCards__loadMore from './assets/js/newsArchive';
import WorkClientModule from './assets/js/workClientModule';
import MainHeader from './assets/js/mainHeader';
import CasestudyIntroductionModule from './assets/js/casestudy-introduction-module';
import CenterCarousel from './assets/js/centerCarousel';
import VideoPan from './assets/js/video-panel';
import ScrollTo from './assets/js/scroll-to';
import AboutScroll from './assets/js/aboutScroll';
import App from './assets/js/animation';
import DetectBrowsers from './assets/js/detectBrowsers';
import ContentOffice from './assets/js/officeBanner';
import MoreCards from './assets/js/more-cards';
import SlickSliderSyncing from './assets/js/slickSliderSync';
import ScrollSlider from './assets/js/scrollSliderBoth';
import peopleSlider from './assets/js/peopleSlider';

const init = () => {
    const GLOBALS = {
        $document: document,
        $body: document.body,
        $window: window,
    };
    [
        {
            component: AboutClients,
            className: 'aboutClients',
        },
        {
            component: CareerHeader,
            className: 'careerHeader',
        },
        {
            component: PeopleExecutive,
            className: 'peopleExecutive',
        },
        {
            component: SplashScreenModule,
            className: 'splashScreenModule',
        },
        {
            component: MainHeader,
            className: 'mainHeader',
        },
        {
            component: CasestudyIntroductionModule,
            className: 'casestudyIntroductionModule',
        },
        {
            component: NewsCards__loadMore,
            className: 'content-news',
        },
        {
            component: WorkClientModule,
            className: 'workClientModule',
        },
        {
            component: CenterCarousel,
            className: 'centerCarousel',
        },
        {
            component: VideoPan,
            className: 'videoPan',
        },
        {
            component: ScrollTo,
            className: 'scrollTo',
        },
        {
            component: AboutScroll,
            className: 'aboutUs',
        },
        {
            component: App,
            className: 'app',
        },

        {
            component: DetectBrowsers,
            className: 'app',
        },

        {
            component: ContentOffice,
            className: 'content-office',
        },

        {
            component: MoreCards,
            className: 'moreCards',
        },

        {
            component: SlickSliderSyncing,
            className: 'slickSliderSyncing',
        },

        {
            component: ScrollSlider,
            className: 'scrollSlider',
        },

        {
            component: peopleSlider,
            className: 'swiper-container',
        },
    ].forEach((obj) => {
        if (obj.className) {
            // search dom element with className of component
            const listDomElem = document.getElementsByClassName(obj.className);
            // create instance for each dom elem with className of component
            for (let i = 0; i < listDomElem.length; i += 1) {
                const factory = new obj.component(GLOBALS, listDomElem[i]); // eslint-disable-line new-cap
                factory.init();
            }
        }
    });
};

document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
        init();
    }
};
