import AbstractComponent from './abstractComponent';

export default class App extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $(window).on('load', function () {
                $(window)
                    .scroll(function () {
                        var windowBottom = $(this).scrollTop() + $(this).innerHeight();
                        $('.heroBanner__caption span,.nextProj__picPanel,.videoPan__title,.picPanV1__textList,.userStoryModule .userStoryModule__item,.linkBack,.moreCards').each(function () {
                            var objectBottom = $(this).offset().top - 970 + $(this).outerHeight();

                            if (objectBottom < windowBottom) {
                                if ($(this).addClass('animationStart')) {
                                }
                            } else {
                                if ($(this).removeClass('animationStart')) {
                                }
                            }
                        });

                        $('.content.content-workDetail .colmnText-1').each(function () {
                            var objectBottom = $(this).offset().top - 620 + $(this).outerHeight();

                            if (objectBottom < windowBottom) {
                                if ($(this).addClass('animationStart')) {
                                }
                            } else {
                                if ($(this).removeClass('animationStart')) {
                                }
                            }
                        });

                        $('.slickSliderSyncing').each(function () {
                            var objectBottom = $(this).offset().top - 620 + $(this).outerHeight();

                            if (objectBottom < windowBottom) {
                                if ($(this).addClass('animationStart')) {
                                }
                            } else {
                                if ($(this).removeClass('animationStart')) {
                                }
                            }
                        });
                        $('.workClientModule__item').each(function () {
                            var objectBottom = $(this).offset().top - 210 + $(this).outerHeight();

                            if (objectBottom < windowBottom) {
                                if ($(this).addClass('animationStart')) {
                                }
                            } else {
                                if ($(this).removeClass('animationStart')) {
                                }
                            }
                        });
                    })
                    .scroll();
            });
            $(".workClientModule__item").hover(function() {
                $(this).css({'transform': 'scale(1.1)','z-index': '3'}).parent().css({'z-index': '2'});
                $('.workClientModule').addClass('movementStop');
            });
            $(".workClientModule__item").mouseleave(function () {
                $(this).css({'transform': 'scale(1)','z-index': '1'}).parent().css({'z-index': '1'});
                $('.workClientModule').removeClass('movementStop');
            });
        }
    }
}
