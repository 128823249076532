import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class CareerHeader extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            var $slider = $('.careerHeader__list');

            if ($slider.length) {
                var currentSlide;
                var slidesCount;
                var sliderCounter = document.createElement('div');
                sliderCounter.classList.add('slider__counter');

                var updateSliderCounter = function (slick) {
                    currentSlide = slick.slickCurrentSlide() + 1;
                    slidesCount = slick.slideCount;
                    $(sliderCounter).text(currentSlide + '/' + slidesCount);
                };

                $slider.on('init', function (event, slick) {
                    $slider.append(sliderCounter);
                    updateSliderCounter(slick);
                });

                $slider.on('afterChange', function (
                    event,
                    slick,
                    currentSlide
                ) {
                    updateSliderCounter(slick, currentSlide);
                });

                $slider.slick({
                    fade: true,
                    arrows: true,
                    infinite: false,
                    speed: 500,
                    autoplaySpeed: 2000,
                    swipeToSlide: true,
                    verticalSwiping: true,
                });
            }
        }
    }
}
