import AbstractComponent from './abstractComponent';

export default class ScrollTo extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('.scrollTo .scrollTo__link').click(function () {
                $('html, body').animate(
                    {
                        scrollTop: $('.scrollTo__section').offset().top - 50,
                    },
                    1500
                );
            });

            $(window).scroll(function () {
                var scroll = $(window).scrollTop();

                $('body').toggleClass(
                    'beforeFooter',
                    scroll >= $('footer').offset().top - 500
                );

                $(window).bind('scroll.alert', function () {
                    var $this = $(this);
                    if ($this.scrollTop() >= 100) {
                        $('body').addClass('someScrollSome');
                    } else {
                        $('body').removeClass('someScrollSome');
                    }
                });
            });
            $(window).scroll();
        }
    }
}
