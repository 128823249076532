import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class PeopleExecutive extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            jQuery('.peopleExecutive__list').slick({
                arrows: false,
                dots: true,
                infinite: false,
                speed: 500,
                autoplaySpeed: 2000,
                swipeToSlide: false,
                verticalSwiping: false,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            swipe: true,
                            swipeToSlide: true,
                        },
                    },
                ],
            });
        }
    }
}
