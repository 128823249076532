import AbstractComponent from './abstractComponent';

export default class ContentOffice extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            let officeLocation = $('.officeLocation');
            let resultHoverFirst = "result_hover_first";
            let resultHoverLast = "result_hover_last";
            let $containerwidth = $(window).width();

            if ($containerwidth > 1100) {
                //Hover
                $(".officeLocation__repeat:first").hover(function () {
                    officeLocation.addClass(resultHoverFirst);
                    officeLocation.removeClass(resultHoverLast);
                });

                $(".officeLocation__repeat:last").hover(function () {
                    officeLocation.addClass(resultHoverLast);
                    officeLocation.removeClass(resultHoverFirst);
                });
            }
            if ($containerwidth < 1099) {
                //Focus
                $(".officeLocation__repeat:first").focus(function () {
                    officeLocation.addClass(resultHoverFirst);
                    officeLocation.removeClass(resultHoverLast);
                    
                });
                $(".officeLocation__repeat:last").focus(function () {
                    officeLocation.addClass(resultHoverLast);
                    officeLocation.removeClass(resultHoverFirst);
                    
                });
            }
            $(".officeLocation__repeat:first").mouseleave(
                function () {
                    officeLocation.removeClass(resultHoverFirst);
                }
            );
            $(".officeLocation__repeat:last").mouseleave(
                function () {
                    officeLocation.removeClass(resultHoverLast);
                }
            );
        }
    }
}
