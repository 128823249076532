import AbstractComponent from './abstractComponent';

export default class MainHeader extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            let lastKnownScrollY = 0;
            let currentScrollY = 0;
            let ticking = false;
            let idOfHeader = 'header';
            let eleHeader = null;
            const classes = {
                pinned: 'header-pin',
                unpinned: 'header-unpin',
            };

            let onScroll = function () {
                currentScrollY = window.pageYOffset;
                requestTick();
            };
            let requestTick = function () {
                if (!ticking) {
                    requestAnimationFrame(update);
                }
                ticking = true;
            };
            let update = function () {
                if (currentScrollY < lastKnownScrollY) {
                    pin();
                } else if (currentScrollY > lastKnownScrollY) {
                    unpin();
                }
                lastKnownScrollY = currentScrollY;
                ticking = false;
            };
            let pin = function () {
                if (eleHeader.classList.contains(classes.unpinned)) {
                    eleHeader.classList.remove(classes.unpinned);
                    eleHeader.classList.add(classes.pinned);
                }
            };
            let unpin = function () {
                if (
                    eleHeader.classList.contains(classes.pinned) ||
                    !eleHeader.classList.contains(classes.unpinned)
                ) {
                    eleHeader.classList.remove(classes.pinned);
                    eleHeader.classList.add(classes.unpinned);
                }
            };
            // $('.logoSprite').clone().appendTo('.burgerMenu__head a');
            window.onload = function () {
                eleHeader = document.getElementById(idOfHeader);
                document.addEventListener('scroll', onScroll, false);
                let myQuirySelecter1 = document.querySelector(
                    '.mainHeader a.logoMark div.logoSpriteJs'
                );
                let myQuirySelecter3 = document.querySelector(
                    '.footer__gif .logoSpriteJs'
                );

                let images = [
                    '/images/logo-animation-1.png',
                    '/images/logo-animation-2.png',
                    '/images/logo-animation-3.png',
                    '/images/logo-animation-4.png',
                    '/images/logo-animation-5.png',
                ];

                localStorage['backgroundIndex'] = !localStorage[
                    'backgroundIndex'
                ]
                    ? 0
                    : +localStorage['backgroundIndex'] + 2 > images.length
                    ? 0
                    : +localStorage['backgroundIndex'] + 1;
                myQuirySelecter1.style.backgroundImage =
                    'url(' + images[+localStorage['backgroundIndex']] + ')';
                myQuirySelecter3.style.backgroundImage =
                    'url(' + images[+localStorage['backgroundIndex']] + ')';
            };

            let mainNav = $('.mainHeader');
            let body = $('body');
            // body.css("overflow", "scroll");

            $('.burgerMenu .hamburger1').on('click', function () {
                mainNav.toggleClass('hide show');

                if (mainNav.hasClass('show')) {
                    body.css('overflow', 'hidden');
                } else {
                    body.css('overflow', 'scroll');
                }
            });

            $('#accountloginsignup h1').click(function () {
                if ($('#takeonebar').hasClass('slamdown')) {
                    $('#takeonebar').removeClass(
                        'slamdown'
                    ); /* missing . before removeClass */
                } else {
                    $('#takeonebar').addClass('slamdown');
                }
            });

            $(window).scroll(function () {
                if ($(this).scrollTop() > 1) {
                    $('header').addClass('sticky');
                } else {
                    $('header').removeClass('sticky');
                }
            });

            $(document).mouseup(function (e) {
                if ($(e.target).closest('.burgerMenu').length === 0) {
                    let mainNav = $('.mainHeader');
                    mainNav.removeClass('show');
                    body.css('overflow', 'scroll');
                    $('.burgerMenu #toggle1').prop('checked', false);
                }
            });
        }
    }
}
