import AbstractComponent from './abstractComponent';
export default class NewsCards__loadMore extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        if (this.exists) {
            $(window).scroll(function () {
                if ($('.newsCards-desktop-3 > .newsCards__item').length > 9) {
                    $('.cardsLoading-1').show();
                }
                if ($(window).scrollTop() >= $('footer').offset().top - 750) {
                    $('.newsCards__item:hidden').slice(0, 9).show();
                }
                if (
                    $('.newsCards__item').length ==
                    $('.newsCards__item:visible').length
                ) {
                    $('.cardsLoading-1').hide();
                }
            });
            
        }
    }
}
