import AbstractComponent from './abstractComponent';

export default class peopleSlider extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            const swiper = new Swiper('.swiper-container', {
                freeMode: true,
                mousewheel: true,
                slidesPerView: 2.5,
                spaceBetween: 0,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                scrollbar: {
                    el: '.js-swiper-scrollbar',
                    draggable: true,
                    snapOnRelease: false,
                },
                breakpoints: {
                    200: {
                        slidesPerView: 1,
                    },
                    600: {
                        slidesPerView: 1.5,
                    },
                    1024: {
                        slidesPerView: 2.5,
                    },
                },
            });
        }
    }
}