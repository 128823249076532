import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class CenterCarousel extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            jQuery('.centerCarousel__list').slick({
                centerMode: true,
                slidesToShow: 1,
                arrows: false,
                dots: true,
                speed: 500,
                autoplaySpeed: 2000,
                swipe: true,
                swipeToSlide: true,
                variableWidth: true,
                // adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            variableWidth: false,
                            // adaptiveHeight: false,
                            dots: false,
                        },
                    },
                ],
            });
        }
    }
}
