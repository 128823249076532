import AbstractComponent from './abstractComponent';

export default class MoreCards extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            if ($(window).width() < 990) {
                let root = document.documentElement;
                const lists = document.querySelectorAll('.moreCards__list');

                lists.forEach(el => {
                const listItems = el.querySelectorAll('.moreCards__list li');
                const n = el.children.length;
                el.style.setProperty('--total', n);
                });
             }
        }
    }
}
