import AbstractComponent from './abstractComponent';
export default class WorkClientModule extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        if (this.exists) {
            $(window).scroll(function () {
                if ($('.workClientModule__list').length > 1) {
                    $('.loadMore').show();
                }
                if ($(window).scrollTop() >= $('footer').offset().top - 600) {
                    $('.workClientModule__list:hidden').slice(0, 1).show();
                }
                if ($('.workClientModule__list').length == $('.workClientModule__list:visible').length)
                {
                    $('.loadMore').hide();
                }
            });
        }
    }
}
