import AbstractComponent from './abstractComponent';
export default class SplashScreenModule extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        if (this.exists) {
            if (sessionStorage.getItem('splash') !== 'true') {
                $('.splashScreenModule').css({ display: 'flex' });
                if ($('.splashScreenModule')[0]) {
                    $('body').css({ overflow: 'hidden' });
                }
                setTimeout(function () {
                    $('.splashScreenModule')
                        .animate({ height: '0%', zIndex: '-100', opacity: 0 })
                        .hide(500);
                    //$('header').css({visibility: 'visible'});
                    document.getElementById("header").style.visibility = "visible";
                    document.getElementById("main").style.visibility = "visible";
                    //$('main').css({visibility: 'visible'});
                    $('body').css({overflow: 'scroll' });
                    sessionStorage.setItem('splash', 'true');
                }, 7000);
            } else {
                document.getElementById("header").style.visibility = "visible";
                document.getElementById("main").style.visibility = "visible";
                $('.splashScreenModule').hide();
            }
            window.onload = function () {
                document.addEventListener('scroll', onScroll, false);
                let myQuirySelecter2 = document.querySelector(
                    '.splashScreenModule .logoSpriteJs'
                );

                let images = [
                    '/images/logo-animation-1.png',
                    '/images/logo-animation-2.png',
                    '/images/logo-animation-3.png',
                    '/images/logo-animation-4.png',
                    '/images/logo-animation-5.png',
                ];

                localStorage['backgroundIndex'] = !localStorage[
                    'backgroundIndex'
                ]
                    ? 0
                    : +localStorage['backgroundIndex'] + 2 > images.length
                    ? 0
                    : +localStorage['backgroundIndex'] + 1;
                myQuirySelecter2.style.backgroundImage =
                    'url(' + images[+localStorage['backgroundIndex']] + ')';
            };
        }
    }
}
