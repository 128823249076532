import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class AboutClients extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            jQuery('.aboutClients__list').slick({
                arrows: true,
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplaySpeed: 2000,
                swipeToSlide: true,
                verticalSwiping: true,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: 'unslick',
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            vertical: true,
                        },
                    },
                ],
            });
        }
    }
}
