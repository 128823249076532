import AbstractComponent from './abstractComponent';

export default class SplashScreenModule extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            $('body').css({ overflow: 'hidden' });
            if ($('.casestudyIntroductionModule')[0]) {
                $('body').css({ overflow: 'hidden' });
            }
            setTimeout(function () {
                $('.casestudyIntroductionModule').fadeOut('slow');
                $('body').css({ overflow: 'scroll' });
            }, 2000);
        }
    }
}
